<template>
    <section class="section">
    <h1><i class="bi bi-chat-right-text text-primary"></i> お知らせ</h1>

    <ul v-if="informations.length">
        <li v-for="information, index in informations" :key="index">{{ information }}</li>
    </ul>
    <p v-else>現在お知らせはありません</p>
    </section>
</template>

<script>
export default {
    name: 'PageHome',
    components: {

    },
    data: () => {
        return {
            informations: []
        }
    }
}
</script>

<style scoped>

</style>
